import Phaser from 'phaser'

import BootScene from './scenes/BootScene'
import PlayScene from './scenes/PlayScene'

function launch(containerId, em, details) 
{    
    const game = new Phaser.Game({
        type: Phaser.AUTO,
        width: window.innerWidth,
        height: window.innerHeight,
        parent: containerId,
        transparent: true,
        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 50 },
                debug: false
            }
        },
        scene: [BootScene, PlayScene],
        _resolution: window.devicePixelRatio,
        resolution: 2
    })

    game.registry.events = em
    game.virusDetails = details

    return game
}

export default launch
export { launch }