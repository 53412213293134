import Phaser from 'phaser'

let currentScene;
let game;
export default class PlayScene extends Phaser.Scene {

    constructor() {
        super({ key: 'PlayScene' })
    }
    
    create()
    {   
        game = this;
        currentScene = this.scene;

        var _this = this;
        
        _this.handsTexture = ['hands','handsB','handsC'];
        _this.handsTextureIndex = 0;
        _this.timer = 0;

        _this.lives;
        _this.livesCounter = 3;
        _this.score = this.game.virusDetails.score;
        _this.gameLevel = this.game.virusDetails.level;
        _this.audioEnabled = this.game.virusDetails.audio;
        _this.hands;
        _this.handDrag;
        
        _this.fallingItems = [
            { itemFalling: "virusA", itemScore: 25 },
            { itemFalling: "virusB", itemScore: 35 },
            { itemFalling: "virusC", itemScore: 15 },
            { itemFalling: "virusD", itemScore: 50 },
            { itemFalling: "virusE", itemScore: -30 },
            { itemFalling: "virusF", itemScore: 30 }
        ];

        _this.fallingItem = {};
        _this.fallingItemArray = [];
        _this.fallingItemCounter = 0;
        _this.isFallingItemCreated = false;
        
        _this.speed = _this.gameLevel == 1 ? 2 : 2 * _this.gameLevel;
        _this.firstDelayNumber = _this.gameLevel == 1 ? 1000 : 1000 * _this.gameLevel * 0.1;
        _this.secondDelayNumber = _this.gameLevel == 1 ? 2000 : 2000 * _this.gameLevel * 0.2;

        _this.isBalanceTextAppeared = false;
        _this.isHighProteinTextAppeared = false;
        _this.isOatMilkTextAppeared = false;
        _this.isMilkDrinkTextAppeared = false;
        _this.isButterPackTextAppeared = false;
        
        //_this.physics.world.on('worldbounds', (body, up, down, left, right)
        _this.physics.world.setBounds(0, 0, window.innerWidth, window.innerHeight, false, false, false, true);
        
        showGameElements();
        
        function showGameElements() {
            
            _this.hands = _this.physics.add.sprite(window.innerWidth / 2, window.innerHeight, "hands");
            _this.hands.setOrigin(0.5, 1);
            _this.hands.setDepth(10);
            _this.hands.alpha = 0;
            _this.hands.setInteractive();
            _this.input.setDraggable(_this.hands);
            _this.hands.setCollideWorldBounds(true);
            _this.hands.body.setSize(_this.hands.width - 60, (_this.hands.height / 2) + 20, 0, 0);
            _this.hands.body.setOffset(30, (_this.hands.height / 2) - 20);
            
            _this.hands.displayWidth = 30;
            _this.hands.displayHeight = 130;
            
            _this.fallingItemContainer = _this.add.container(0, 0);
            _this.fallingItemContainer.setDepth(3);
            
            var gameElementsTween = _this.tweens.add({
                targets: [ _this.scoreBg, _this.scoreText, _this.lives, _this.hands, _this.instructionText, _this.swipeIcon ],
                alpha: { from: 0, to: 1 },
                delay: 0,
                ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                duration: 500,
                repeat: 0,            // -1: infinity
                yoyo: false
            })
            
            return {
                showGameElements
            };
        }
        
        //Spawn the falling items
        function spawnItems() {
            var delay = Phaser.Math.Between(_this.firstDelayNumber, _this.secondDelayNumber)
            _this.spawnTimer = _this.time.delayedCall(delay, spawnItems); 

            _this.isFallingItemCreated = true;

            var randomItemNumber = Phaser.Math.Between(0, _this.fallingItems.length - 1);

            _this.fallingItem = _this.physics.add.sprite(Phaser.Math.Between(0, window.innerWidth), -100, _this.fallingItems[randomItemNumber].itemFalling);
            _this.fallingItem.setOrigin(0, 1);
            _this.fallingItem.setDepth(2);
            _this.fallingItem.setCollideWorldBounds(true)            
            _this.fallingItem.body.onWorldBounds = true
            _this.fallingItem.body.bounce.set(1)

            _this.physics.world.on('worldbounds', function(body){
                if (body.gameObject === this) {

                    let $this = this;
                    
                    $this.setTexture('virusCapow');
                    $this.body.enable = false;

                    _this.tweens.add({
                        targets: $this,
                        alpha: { from: 0, to: 1 },
                        delay: 0,
                        ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                        duration: 400,
                        repeat: 0,            // -1: infinity
                        yoyo: false,
                        onComplete: function() {
                            $this.disableBody(true, true);
                        }
                    });
                    
                    if(_this.gameLevel == 3 && $this.fallingItemName != 'virusE'){
                        _this.livesCounter = _this.livesCounter - 1;
                        _this.registry.events.emit('update-score', { lives: _this.livesCounter, score: _this.score, msg: "Careful! You cant let them pass!" });
                    }

                  }
            }, _this.fallingItem);

            
            _this.fallingItemContainer.add(_this.fallingItem);

            _this.fallingItem.fallingItemScore = _this.fallingItems[randomItemNumber].itemScore;
            _this.fallingItem.fallingItemName = _this.fallingItems[randomItemNumber].itemFalling;
            
            // console.log( _this.fallingItem.fallingItemName);
            
            let scale = Math.random();
            if(scale < 0.5) scale = 0.5;
            
            _this.fallingItem.displayWidth = 100 * scale;
            _this.fallingItem.displayHeight = 94 * scale;
            

            var fallingItemPos = _this.fallingItem.x + _this.fallingItem.displayWidth;

            if(fallingItemPos > window.innerWidth) {
                _this.fallingItem.x = _this.fallingItem.x - _this.fallingItem.displayWidth;
            }
        }
        
        //Function when the user pressed the hands 
        _this.input.on('dragstart', function (pointer, gameObject) {
            
            _this.handDrag = gameObject;
            
            if(!_this.isFallingItemCreated){
                spawnItems();
                
                _this.hideInstructionTween = _this.tweens.add({
                    targets: [_this.instructionText, _this.swipeIcon],
                    alpha: { from: 1, to: 0 },
                    delay: 0,
                    ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                    duration: 500,
                    repeat: 0,            // -1: infinity
                    yoyo: false,
                    onComplete: function() {
                                                
                       // _this.gameTimer = _this.time.delayedCall(60000, _this.fadeOutGameElements, [_this.hands, _this.scoreText, _this.scoreBg, _this.itemText, _this.itemText2, _this.lives, _this.fallingItemContainer, _this.handDrag, _this], _this);
                    }
                });
            }

        });
        
        //Function when the user start dragging the hands 
        _this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
        });
        
        
        
        /*if(window.screen.width < 350) {
            bottomMilkBg.scaleY = 0.8;
            bottomMilkBg.scalex = 0.8;
            
            // console.log("Resized Height: " + (bottomMilkBg.height - (bottomMilkBg.height - (bottomMilkBg.height * 0.8))));
            product.scaleY = 0.8;
        }*/
    }

    update(time, delta)
    {
        /* 
        this.timer += delta;
        while (this.timer > 200){
            this.timer -= 200
            this.hands.setTexture(this.handsTexture[this.handsTextureIndex]);
            this.handsTextureIndex++;
            if(this.handsTextureIndex >= 3){
                this.handsTextureIndex = 0;
            }
        }
        */

        if(this.isFallingItemCreated === true)
        {   
            this.physics.add.overlap(this.hands, this.fallingItem, this.addMinusScores, null, this);
        
            if(this.livesCounter !== 0) {
                this.addGravity();
            }
        }
    }
    
    //Add or Minus scores based on the item's catched. 
    addMinusScores (hands, item)
    {            
        item.disableBody(true, true);
        item.destroy();        
        
        if(this.livesCounter !== 0) {

            this.score = this.score + item.fallingItemScore;
            //this.scoreText.setText("SCORE: " + this.score);
            this.registry.events.emit('update-score', { lives: this.livesCounter, score: this.score, msg: "Point earned" });

            // console.log(item.fallingItemName);

            if(item.fallingItemName === "virusE") {

                this.livesCounter = this.livesCounter - 1;

                if(this.audioEnabled == true) this.sound.play('audioCry');

                if(this.livesCounter === 0) {
                    this.livesCounter = 0;
                    //this.spawnTimer.remove();
                    
                    //this.fadeOutGameElements(this.hands, this.scoreText, this.scoreBg, this.itemText, this.itemText2, this.lives, this.fallingItemContainer, this.handDrag, this);
            
                  //  this.lives.setTexture("lives" + this.livesCounter);
                  this.registry.events.emit('update-score', { lives: this.livesCounter, score: this.score, msg: "You ran out of lifes" });
                }
                else {
                   // this.lives.setTexture("lives" + this.livesCounter);
                   this.registry.events.emit('update-score', { lives: this.livesCounter, score: this.score, msg: "You lost one life" });
                }

                this.minusSugarPoints = this.add.text(this.hands.x + (this.hands.displayWidth / 2), this.hands.y - this.hands.displayHeight, "-30", {
                   fontFamily: "Nunito", fontSize: 20,
                });
                this.minusSugarPoints.setOrigin(1, 1);
                this.minusSugarPoints.alpha = 0;

                this.animatePointsAddedMinus(this.minusSugarPoints);
            }

            else {
                
                this.addBalancePoints = this.add.text(this.hands.x + (this.hands.displayWidth / 2), this.hands.y - this.hands.displayHeight, "+25", {
                   fontFamily: "Nunito", fontSize: 20,
                });
                this.addBalancePoints.setOrigin(1, 1);
                this.addBalancePoints.alpha = 0;

                var capow = this.physics.add.sprite(this.hands.x, this.hands.y - this.hands.displayHeight, "virusCapow");
                capow.displayWidth = 500;
                capow.displayHeight = 361;
                capow.scale = 0.2;

                if(this.audioEnabled == true) this.sound.play('audioCapow');

                this.capowAnimation = this.tweens.add({
                    targets: capow,
                    alpha: { from: 0, to: 1 },
                    y: { from: capow.y, to: capow.y - 25 },
                    scale: { from: 0.2, to: 0.15 },
                    delay: 0,
                    ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                    duration: 300,
                    repeat: 0,            // -1: infinity
                    yoyo: true,
                    onComplete: function() {
                        capow.destroy();
                    }
                });
                

                //this.animatePointsAddedMinus(this.addBalancePoints);
            }

        }
    }
    
    animatePointsAddedMinus(pointsToAnimate) {
        if(pointsToAnimate.x > window.innerWidth) {
            pointsToAnimate.x = window.innerWidth;
        }
        else if((pointsToAnimate.x - pointsToAnimate.width) < 0) {
            pointsToAnimate.x = pointsToAnimate.x + pointsToAnimate.width;
        }
        
        this.pointsAddedMinusAnimteIn = this.tweens.add({
            targets: pointsToAnimate,
            alpha: { from: 0, to: 1 },
            y: { from: pointsToAnimate.y, to: pointsToAnimate.y - 10 },
            delay: 0,
            ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 300,
            repeat: 0,            // -1: infinity
            yoyo: false,
        });
        
        this.pointsAddedMinusAnimteOut = this.tweens.add({
            targets: pointsToAnimate,
            alpha: { from: 1, to: 0 },
            y: { from: pointsToAnimate.y - 10, to: pointsToAnimate.y - 10 },
            delay: 300,
            ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 300,
            repeat: 0,            // -1: infinity
            yoyo: false,
            onComplete: function() {
                pointsToAnimate.destroy();
            }
        });
    }
    
    //Change gravity/speed once player reached certain scores.
    addGravity() {
        if(this.score >= 200 && this.score < 400) {
            this.fallingItem.setGravityY(100);
            this.firstDelayNumber = 800;
            this.secondDelayNumber = 1500;
        }
        
        else if(this.score >= 400 && this.score < 800) {
            this.fallingItem.setGravityY(150);
            this.firstDelayNumber = 500;
            this.secondDelayNumber = 1200;
        }
        
        else if(this.score >= 800 && this.score < 1500) {
            this.fallingItem.setGravityY(200);
            this.firstDelayNumber = 300;
            this.secondDelayNumber = 1000;
        }
        
        else if(this.score >= 1500 && this.score < 2500) {
            this.fallingItem.setGravityY(250);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 600;
        }
        
        else if(this.score >= 2500 && this.score < 3500) {
            this.fallingItem.setGravityY(300);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 3500 && this.score < 4500) {
            this.fallingItem.setGravityY(350);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 4500 && this.score < 5500) {
            this.fallingItem.setGravityY(400);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 5500 && this.score < 6500) {
            this.fallingItem.setGravityY(500);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 6500 && this.score < 7500) {
            this.fallingItem.setGravityY(600);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 7500 && this.score < 10000) {
            this.fallingItem.setGravityY(700);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 10000 && this.score < 15000) {
            this.fallingItem.setGravityY(800);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 15000 && this.score < 25000) {
            this.fallingItem.setGravityY(1000);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 500;
        }
        
        else if(this.score >= 25000) {
            this.fallingItem.setGravityY(1500);
            this.firstDelayNumber = 100;
            this.secondDelayNumber = 400;
        }
    }

    fadeOutGameElements(hands, scoreText, scoreBg, itemText, itemText2, lives, fallingItemContainer, handDrag, _this) {
        _this.spawnTimer.remove();
        
        fallingItemContainer.removeAll();
        //handDrag.removeInteractive();
        
        // console.log(itemText.alpha);
        
        if(itemText.alpha === 0) {
            // console.log("ZERO")
            
            this.gameElementsAnimOut = this.tweens.add({
                targets: [hands, scoreText, scoreBg, lives],
                alpha: { from: 1, to: 0 },
                delay: 500,
                ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                duration: 300,
                repeat: 0,            // -1: infinity
                yoyo: false,
                onComplete: function() {
                    scoreText.destroy();
                    scoreBg.destroy();
                    itemText.destroy();
                    itemText2.destroy();
                    lives.destroy();

                    _this.input.on('dragend', function (pointer, gameObject) {
                        hands.destroy();
                    });
                    
                    _this.gameTimer.remove();
                }
            });
        }
        else if(itemText.alpha === 1) {
            // console.log("ONE")
            this.gameElementsAnimOut = this.tweens.add({
                targets: [hands, scoreText, scoreBg, itemText, itemText2, lives],
                alpha: { from: 1, to: 0 },
                delay: 500,
                ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                duration: 300,
                repeat: 0,            // -1: infinity
                yoyo: false,
                onComplete: function() {
                    scoreText.destroy();
                    scoreBg.destroy();
                    itemText.destroy();
                    itemText2.destroy();
                    lives.destroy();

                    _this.input.on('dragend', function (pointer, gameObject) {
                        hands.destroy();
                    });
                    
                    _this.gameTimer.remove();
                }
            });
        }
        
        
        // this.bottomMilkBgAnimBack = this.tweens.add({
        //     targets: this.bottomMilkBg,
        //     y: { from: this.bottomMilkBg.y, to: this.bottomMilkBgYPos },
        //     delay: 500,
        //     ease: 'Cubic',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
        //     duration: 500,
        //     repeat: 0,            // -1: infinity
        //     yoyo: false,
        //     onComplete: function() {
        //         _this.showEndScreen(_this);
        //     }
        // });

        this.gameOver();
    }
    
    closeGame()
    {
        currentScene.start('ScoreScene');
        game.registry.events.emit('close-game');
    }

    gameOver() 
    {
        console.log('gameover')
        // post score
        var finalscore = this.score;
        game.registry.events.emit('update-score', finalscore);

        currentScene.start('ScoreScene');
    }
}
