  
import Phaser from 'phaser'

let baseURL = 'https://static.appetitecreative.com/resources/baggl/'
let lang;

export default class BootScene extends Phaser.Scene {
    
    constructor()
    {
        super({ key: 'BootScene' })
    }
    
    preload()
    {

        this.load.setBaseURL(baseURL);
        
        this.load.image("hands", "assets/syringe_1.png");
        this.load.image("handsB", "assets/syringe_4.png");
        this.load.image("handsC", "assets/syringe_5.png");

        this.load.image("capow", "assets/CAPOW.png");
        this.load.image("virusCapow", "assets/virus_6.png");
        
        //Load Falling Items
        this.load.image("virusA", "assets/virus_1.png");
        this.load.image("virusB", "assets/virus_2.png");
        this.load.image("virusC", "assets/virus_3.png");
        this.load.image("virusD", "assets/virus_7.png");
        this.load.image("virusE", "assets/hw__pumpkin.png");
        this.load.image("virusF", "assets/virus_9.png");

        // sound
        this.load.audio('audioCapow', 'sounds/capow.wav', { instances: 1 });
        this.load.audio('audioCry', 'sounds/cry.wav', { instances: 1 });
    }
    
    create()
    {
        this.background = this.add.image(window.innerWidth / 2, window.innerHeight / 2, "background");
        this.background.setOrigin(0.5);
        
        var text = this.add.text(window.innerWidth / 2, window.innerHeight / 2, 'Loading...', {
           fontFamily: "Nunito", fontSize: 25
        });
        
        text.setOrigin(0.5);
        this.scene.start('PlayScene');
    }

    update()
    {
        
    }
}